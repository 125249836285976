import styles from "./Login.module.css";
import { Form, Button } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import logo from "../logo.svg";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { useContext, useState } from "react";
import { authContext } from "../context/AuthContext";

function SignIn() {
  const { setTimeActive } = useContext(authContext);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const signin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        if (!auth.currentUser?.emailVerified) {
          navigate("/verify-email");
        } else {
          navigate("/");
        }
      })
      .catch((err) => alert(err.message.slice(10)));
  };

  return (
    <div className={styles.container}>
      <div className={styles.overlay}>
        <div style={{ marginBottom: "35px" }}>
          <img src={logo} width={193} height={45}></img>
        </div>
        <form onSubmit={signin} id="signin_form"></form>
        <div className={styles.section}>
          <Form.Control
            type="email"
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            form="signin_form"
            required
          />
        </div>
        <div className={styles.section} style={{ marginBottom: "20px" }}>
          <Form.Control
            type="password"
            value={password}
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            form="signin_form"
            required
          />
        </div>
        <div
          className={styles.section}
          style={{ color: "#333", fontSize: "14px", textDecoration: "none" }}
        >
          <Link to="/forgot-password">Forgot your password?</Link>
        </div>
        <div>
          <Button type="submit" form="signin_form" variant="primary">
            Sign In
          </Button>
        </div>
      </div>
      <div className={styles.overlayRight}>
        <div className={styles.title}>Sign up here</div>
        <div>
          To access all the features you need to create an account
        </div>
        <div style={{ marginTop: "30px" }}>
          <Link to="/sign-up">
            <Button
              variant="outline-primary"
              style={{
                borderColor: "white",
                background: "none",
                color: "white",
              }}
            >
              Sign Up
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
