import { createContext, useState } from "react";

const playgroundContext = createContext();

function Provider({ children }) {
  const base_url = "http://api.pharmabrain.stg-atacana-innovation.com";
  // || "http://localhost:5000";

  const [playground, setPlayground] = useState({
    temperature: 0,
    maxLength: 512,
    textarea: "",
    model: "gpt-4",
    spinner: false,
    responseAlert: "",
    endpoint: base_url + "/playground/",
    placeholder: "Type your prompt here",
  });

  function resetPlayground() {
    setPlayground(() => {
      return {
        ...playground,
        temperature: 0,
        maxLength: 512,
        textarea: "",
        model: "gpt-4",
      };
    });
  }

  return (
    <playgroundContext.Provider
      value={{ playground, setPlayground, resetPlayground }}
    >
      {children}
    </playgroundContext.Provider>
  );
}

export { playgroundContext };
export default Provider;
