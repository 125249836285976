import { useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { restrictAccessModalContext } from "../context/RestrictAccessModalContext";
import { authContext } from "../context/AuthContext";
import { auth } from "../firebase";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

function RestrictAccessModal() {
  const { restrictAccessModalState, closeRestrictAccessModal } = useContext(
    restrictAccessModalContext
  );

  const { currentUser, setCurrentUser } = useContext(authContext);
  const navigate = useNavigate();

  const signout = async () => {
    await closeRestrictAccessModal();
    await signOut(auth);
    await setCurrentUser(null, navigate("/"));
    window.location.reload();
  };

  return (
    <Modal show={restrictAccessModalState} onHide={closeRestrictAccessModal}>
      <Modal.Header closeButton>
        <Modal.Title className="modal-title h5">
          {!currentUser ? "Sign In Required" : "Email Verification Required"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!currentUser ? (
          <div>
            <p>
              <b>Try out Chat GPT-4!</b> To access this feature, please sign in.
            </p>
          </div>
        ) : (
          <p>
            To access <b>all</b> features, please verify your email, otherwise,
            sign out and enjoy our free access features.
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          as={Link}
          variant="primary"
          to={!currentUser ? "/sign-in" : "/verify-email"}
          onClick={
            !currentUser
              ? closeRestrictAccessModal
              : () => {
                  closeRestrictAccessModal();
                }
          }
        >
          {!currentUser ? "Sign In" : "Verify Email"}
        </Button>
        {currentUser && (
          <Button variant="secondary" onClick={signout}>
            Sign Out
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default RestrictAccessModal;
