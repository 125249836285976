import { createContext, useState } from "react";

const formDataContext = createContext();

function Provider({ children }) {
  const base_url = "http://api.pharmabrain.stg-atacana-innovation.com";
  //|| "http://localhost:5000";

  const [formData, setFormData] = useState([
    {
      feature: 0,
      temperature: 0,
      toneOfVoice: "professional, and concise",
      attachment: "",
      maxLength: 512,
      numberOfBulletPoints: 5,
      numberOfSuggestions: 5,
      topicOfInterest: "",
      textInput: "",
      response: "",
      responsePlaceholder: "Your summary will appear here",
      endpoint: base_url + "/summarize/bullet-points/",
      spinner: false,
      alert: ""
    },
    {
      feature: 1,
      temperature: 0,
      toneOfVoice: "professional, and concise",
      attachment: "",
      maxLength: 512,
      numberOfBulletPoints: 5,
      numberOfSuggestions: 5,
      topicOfInterest: "",
      textInput: "",
      response: "",
      responsePlaceholder: "Your title suggestions will appear here",
      endpoint: base_url + "/generate/title/",
      spinner: false,
      alert: ""
    },
    {
      feature: 2,
      temperature: 0,
      toneOfVoice: "professional, and concise",
      attachment: "",
      maxLength: 512,
      numberOfBulletPoints: 5,
      numberOfSuggestions: 5,
      topicOfInterest: "",
      textInput: "",
      response: "",
      responsePlaceholder: "Your key events will appear here",
      endpoint: base_url + "/extract/key-events/",
      spinner: false,
      alert: ""
    },
  ]);

  function updateFormData(feature, key, value) {
    setFormData((prev) => {
      return prev.map((item) => {
        if (item.feature == feature) {
          return { ...item, [key]: value };
        }
        return item;
      });
    });
  }

  function resetFeatureOnFormData(feature) {
    setFormData((prev) => {
      return prev.map((item) => {
        if (item.feature == feature) {
          return {
            ...item,
            temperature: 0,
            toneOfVoice: "professional, and concise",
            attachment: "",
            maxLength: 512,
            numberOfBulletPoints: 5,
            numberOfSuggestions: 5,
            topicOfInterest: "",
            textInput: "",
            response: "",
          };
        }
        return item;
      });
    });
  }

  return (
    <formDataContext.Provider value={{ formData, updateFormData, resetFeatureOnFormData }}>
      {children}
    </formDataContext.Provider>
  );
}

export { formDataContext };
export default Provider;
